import React, { useState } from 'react'
import { imageLoader } from '@/utils/ImageLoaders'
import Image from 'next/image'

type ButtonThemeWrapperProps = {
  children: React.ReactNode
  theme:
    | 'primary'
    | 'secondary'
    | 'transparent'
    | 'transparentLight'
    | 'light'
    | 'dark'
  showIcon?: boolean
  isDisabled?: boolean
  isFull?: boolean
}

type ButtonIconProps = {
  color: string
  showIcon?: boolean
}

export function ButtonIcon({ color, showIcon }: ButtonIconProps): JSX.Element {
  const icon =
    color === 'sand'
      ? 'https://media.foratravel.com/image/upload/v1715877463/icon-upload-sand_uyrvwk.svg'
      : 'https://media.foratravel.com/image/upload/v1715877461/icon-upload-charcoal_bnrcxt.svg'
  return (
    <>
      {showIcon && (
        <div className="w-3 ml-3">
          <Image
            loader={({ src }) =>
              imageLoader({
                src: src,
                width: 24,
                quality: 90,
              })
            }
            src={icon}
            alt={icon}
            width={24}
            height={24}
            layout="responsive"
            sizes="10vw"
          />
        </div>
      )}
    </>
  )
}

export function ButtonThemeWrapper({
  children,
  theme,
  showIcon,
  isDisabled,
  isFull = false,
}: ButtonThemeWrapperProps): JSX.Element {
  const [isHovered, toggleIsHovered] = useState<boolean>(false)
  const sharedStyles =
    'shared-button-styles h-full fora-text-button-1 transition duration-200 ease inline-block border ' +
    (isFull ? 'w-full text-center' : '')
  const primaryStyles =
    'border-blackSand bg-blackSand text-sand hover:bg-transparent hover:text-blackSand'
  const secondaryStyles = `border-blackSand bg-sand text-blackSand hover:bg-blackSand hover:text-sand`
  const darkStyles = `border-blackSand bg-blackSand text-sand hover:bg-sand hover:text-blackSand place-content-center`
  const lightStyles = `border-blackSand bg-sand-transparent text-blackSand hover:bg-blackSand hover:text-sand`
  const transparentStyles = `border-blackSand bg-transparent text-blackSand hover:bg-blackSand hover:text-sand`
  const transparentLightStyles = `hover:border-blackSand bg-transparent text-sand hover:bg-blackSand border-sand`
  const primaryStylesDisabled = 'border-stone bg-stone text-sand'
  const secondaryStylesDisabled = 'border-stone  bg-sand text-stone'
  const transparentStylesDisabled = 'border-stone  bg-sand text-stone'
  const lightStylesDisabled = 'border-darkShell  bg-sand text-darkShell'

  const disabledStyleOptions = {
    primary: primaryStylesDisabled,
    secondary: secondaryStylesDisabled,
    transparent: transparentStylesDisabled,
    light: lightStylesDisabled,
    dark: darkStyles,
  }
  const styleOptions = {
    primary: primaryStyles,
    secondary: secondaryStyles,
    transparent: transparentStyles,
    transparentLight: transparentLightStyles,
    light: lightStyles,
    dark: darkStyles,
  }
  const disabledStyles = disabledStyleOptions[theme]
  const styles = styleOptions[theme]

  return (
    <>
      {theme === 'primary' ? (
        <div
          className={`${sharedStyles} ${isDisabled ? disabledStyles : styles}`}
          onMouseEnter={() => toggleIsHovered(true)}
          onMouseLeave={() => toggleIsHovered(false)}
        >
          <div className="flex items-center justify-center h-full">
            {!isHovered ? (
              <ButtonIcon color="sand" showIcon={showIcon} />
            ) : (
              <ButtonIcon color="blackSand" showIcon={showIcon} />
            )}
            <>{children}</>
          </div>
        </div>
      ) : (
        <div
          className={`${sharedStyles} ${isDisabled ? disabledStyles : styles}`}
        >
          <div className="flex items-center justify-center">
            <>{children}</>
          </div>
        </div>
      )}
    </>
  )
}
